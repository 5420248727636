<template>
  <div class="w-full">
    <div class="relative">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
      >
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <vue-select
        v-model="search"
        :options="data ? extensionListConcat : quickData"
        group-by="isConstructor"
        label-by="label"
        value-by="value"
        :close-on-select="closeOnSelect ? true : false"
        searchable
        :loading="isLoading"
        :multiple="isMultiple ? true : false"
        :max="hasMax"
        :placeholder="selectedCustomer ? selectedCustomer : placeholder"
        @search:input="handleSearchInput"
        :maxHeight="maxHeight"
        class="text-left block w-1/2 bg-white border border-gray-300 rounded-md py-1 pl-10 pr-3 text-sm placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 encom_primary_border_focus sm:text-sm z-20"
      >
        <template #dropdown-item="{ option }">
          <template v-if="option.isConstructor">
            <div class="border-b border-gray-400 flex justify-center">
              {{ option.label }}
            </div>
          </template>
          <template v-else>
            <div class="inline-flex">
              <div class="pl-1">
                {{ option.label }}
              </div>
            </div>
          </template>
        </template>
      </vue-select>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import { SearchIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

export default {
  props: [
    "placeholder",
    "selectedCustomer",
    "searchMethod",
    "data",
    "closeOnSelect",
    "isMultiple",
    "selectAllElements",
    "selectedAllElements",
    "clearAllElements",
    "elementToRemove",
    "maxHeight",
    "groupData",
    "extensionListConcat",
    "selectedElement",
    "hasMax",
  ],
  components: {
    VueSelect,
    SearchIcon,
  },
  data() {
    return {
      quickData: [],
      search: "",
      searchableTerm: "",
      isLoading: false,
      isAllSelected: true,
      concatenatedData: [],
    };
  },
  methods: {
    handleSearchInput(event) {
      this.searchableTerm = event.target.value;
    },
    async getQuick(term) {
      try {
        if (term) {
          this.isLoading = true;

          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${term}`
          );
          this.quickData = res.data;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.errorHandling(error);
      }
    },
  },
  watch: {
    searchableTerm: function(val) {
      if (this.searchMethod) {
        if (val.length > 2) {
          this.searchMethod(val);
        }
      }
    },
    search: function(val) {
      let value = val;
      let arrayValue = [];
      if (typeof val === "string") {
        arrayValue[0] = val;
        value = arrayValue;
      }
      if (value) {
        this.$router.replace({ tableElement: null, period: null });
        this.isLoading = false;
        this.$store.dispatch("pbxElements", value);
        this.$emit("action", value);
      }
    },
    selectAllElements: function(val) {
      let selectAllArray = [];
      let selectAllArray2 = [];
      selectAllArray = this.extensionListConcat.filter((el) => {
        return el.isConstructor;
      });
      for (let index = 0; index < selectAllArray.length; index++) {
        const element = selectAllArray[index].value;
        for (let index2 = 0; index2 < element.length; index2++) {
          selectAllArray2.push(element[index2]);
        }
      }
      this.search = selectAllArray2;
      this.$emit("all-selected", true);
    },
    selectedAllElements: function(val) {
      if (val) {

        let selectAllArray = [];
        let selectAllArray2 = [];
        selectAllArray = this.extensionListConcat.filter((el) => {
          return el.isConstructor;
        });
        for (let index = 0; index < selectAllArray.length; index++) {
          const element = selectAllArray[index].value;
          for (let index2 = 0; index2 < element.length; index2++) {
            selectAllArray2.push(element[index2]);
          }
        }
        this.search = selectAllArray2;
        this.$emit("all-selected", true);
      } else {
        this.search = "";
        this.$emit("all-selected", false);
      }
    },
    clearAllElements: function(val) {
      this.search = [];
      this.$emit("all-selected", false);
    },
    elementToRemove: {
      handler(val) {
        let splitVal = val.split(" - ");
        const filteredSearch = this.search.filter(
          (element) => element != splitVal[0]
        );
        this.search = filteredSearch;
      },
      deep: true,
    },
  },
  mounted() {
    // if (this.pbxElements) {
    //   console.log(
    //     "file: QuickSearch.vue ~ line 199 ~ mounted ~ this.pbxElements",
    //     this.pbxElements
    //   );
    //   this.search = this.pbxElements;
    // }
  },
  computed: {
    ...mapGetters(["pbxElements"]),
  },
};
</script>

<style></style>
